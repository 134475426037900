import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// import CartHome from "./components/Home";
// import NavBar from "./components/NavBar";

import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadUser } from "./slices/authSlice";
import CheckoutSuccess from "./components/CheckoutSuccess";


function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUser(null));
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Routes>
       <Route path="/" element={<CheckoutSuccess />} />
      </Routes>
      
    </BrowserRouter>
  );
}

export default App;
